$imgPath: '../images/';

/* テキスト飛ばし
------------------------------------------*/
@mixin bg {
  text-indent: 110%;
  white-space: nowrap;
  overflow: hidden;
}//@include bg

@mixin bg-img($img, $width, $height, $display) {
  width : $width;
  height: $height;
  background: {
    image : url($imgPath + $img);
    size  : $width $height;
    repeat: no-repeat;  
  }
  @include bg;
  display: $display;
}//@include bg-img($img, $width, $height, $display);
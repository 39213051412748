
.areaStyle01{
  width: 800px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 0;
  padding-right: 0;
}
	
section{
	.item:nth-child(2n-1){float: left;}
	.item:nth-child(2n){float: right;}
.item{
	width: 380px;
	.itemThumb{
		height: 200px;
	}
}
}
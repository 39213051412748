@charset "UTF-8";
/*====================================================================================
0）リセット
====================================================================================*/
/*! sanitize.css v3.3.0 | CC0 1.0 Public Domain | github.com/10up/sanitize.css */
/*
 * Normalization
 */
abbr[title] {
  text-decoration: underline;
  text-decoration: underline dotted;
}

audio:not([controls]) {
  display: none;
}

b,
strong {
  font-weight: bolder;
}

button {
  -webkit-appearance: button;
  overflow: visible;
}

button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

button:-moz-focusring,
input:-moz-focusring {
  outline: 1px dotted ButtonText;
}

button,
select {
  text-transform: none;
}

details {
  display: block;
}

hr {
  overflow: visible;
}

html {
  -ms-overflow-style: -ms-autohiding-scrollbar;
  overflow-y: scroll;
  -webkit-text-size-adjust: 100%;
}

input {
  -webkit-border-radius: 0;
}

input[type="button"], input[type="reset"], input[type="submit"] {
  -webkit-appearance: button;
}

input[type="number"] {
  width: auto;
}

input[type="search"] {
  -webkit-appearance: textfield;
}

input[type="search"]::-webkit-search-cancel-button, input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

main {
  display: block;
}

pre {
  overflow: auto;
}

progress {
  display: inline-block;
}

summary {
  display: block;
}

svg:not(:root) {
  overflow: hidden;
}

template {
  display: none;
}

textarea {
  overflow: auto;
}

[hidden] {
  display: none;
}

/*
 * Universal inheritance
 */
*,
::before,
::after {
  box-sizing: inherit;
}

* {
  font-size: inherit;
  line-height: inherit;
}

::before,
::after {
  text-decoration: inherit;
  vertical-align: inherit;
}

button,
input,
select,
textarea {
  font-family: inherit;
  font-style: inherit;
  font-weight: inherit;
}

/*
 * Opinionated defaults
 */
* {
  margin: 0;
  padding: 0;
}

*,
::before,
::after {
  border-style: solid;
  border-width: 0;
}

a,
area,
button,
input,
label,
select,
textarea,
[tabindex] {
  touch-action: manipulation;
}

select {
  -moz-appearance: none;
  -webkit-appearance: none;
}

select::-ms-expand {
  display: none;
}

select::-ms-value {
  color: currentColor;
}

svg {
  fill: currentColor;
}

[aria-busy="true"] {
  cursor: progress;
}

[aria-controls] {
  cursor: pointer;
}

[aria-disabled] {
  cursor: default;
}

[hidden][aria-hidden="false"] {
  clip: rect(0 0 0 0);
  display: inherit;
  position: absolute;
}

[hidden][aria-hidden="false"]:focus {
  clip: auto;
}

/*
 * Configurable defaults
 */
* {
  background-repeat: no-repeat;
}

:root {
  background-color: #ffffff;
  box-sizing: border-box;
  color: #000000;
  cursor: default;
  font: 66.6666666667% sans-serif;
}

a {
  text-decoration: none;
}

audio,
canvas,
iframe,
img,
svg,
video {
  vertical-align: middle;
}

button,
input,
select,
textarea {
  background-color: transparent;
  color: inherit;
}

button,
[type="button"],
[type="date"],
[type="datetime"],
[type="datetime-local"],
[type="email"],
[type="month"],
[type="number"],
[type="password"],
[type="reset"],
[type="search"],
[type="submit"],
[type="tel"],
[type="text"],
[type="time"],
[type="url"],
[type="week"],
select,
textarea {
  min-height: 1.5em;
}

code,
kbd,
pre,
samp {
  font-family: monospace, monospace;
}

nav ol,
nav ul {
  list-style: none;
}

small {
  font-size: 75%;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

textarea {
  resize: vertical;
}

::-moz-selection {
  background-color: #b3d4fc;
  color: #ffffff;
  text-shadow: none;
}

::selection {
  background-color: #b3d4fc;
  color: #ffffff;
  text-shadow: none;
}

/* テキスト飛ばし
------------------------------------------*/
/*====================================================================================
エリアの設定
====================================================================================*/
html, body {
  overflow-x: hidden;
  　position: relative;
}

/*====================================================================================
テキスト
====================================================================================*/
body {
  -webkit-font-smoothing: antialiased;
}

/*------------------------------------------
基本フォント
------------------------------------------*/
h1, h2, h3, h4, h5, h6, p, blockquote, pre, abbr, address, cite, code, dt, dd, li, fieldset, form,
label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, figcaption, figure {
  color: #303030;
  font-size: 14px;
  font-weight: 300;
  font-family: "游ゴシック体", "YuGothic", "ヒラギノ角ゴシック", "Hiragino Sans", "ヒラギノ角ゴ ProN W3", "HiraKakuProN-W3", "メイリオ", Meiryo, "ＭＳ Ｐゴシック", sans-serif;
  text-align: left;
  line-height: 20px;
  letter-spacing: 0;
  word-wrap: break-word;
}

/*------------------------------------------
テキストスタイル
------------------------------------------*/
/* リンク
------------------------------------------*/
a {
  color: #303030;
}

.txtOverF {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

/* 行揃え
------------------------------------------*/
.ta-le {
  text-align: left !important;
}

.ta-ce {
  text-align: center !important;
}

.ta-ri {
  text-align: right !important;
}

@media screen and (max-width: 790px) {
  .ta-le_sp {
    text-align: left !important;
  }
  .ta-ce_sp {
    text-align: center !important;
  }
  .ta-ri_sp {
    text-align: right !important;
  }
}

@media screen and (min-width: 790px) {
  .ta-le_pc {
    text-align: left !important;
  }
  .ta-ce_pc {
    text-align: center !important;
  }
  .ta-ri_pc {
    text-align: right !important;
  }
}

/* 縦揃え（vertiacl-align
------------------------------------------*/
.va-to {
  vertical-align: top !important;
}

.va-mi {
  vertical-align: middle !important;
}

.va-bo {
  vertical-align: bottom !important;
}

@media screen and (max-width: 790px) {
  .va-to_sp {
    vertical-align: top !important;
  }
  .va-mi_sp {
    vertical-align: middle !important;
  }
  .va-bo_sp {
    vertical-align: bottom !important;
  }
}

@media screen and (min-width: 790px) {
  .va-to_pc {
    vertical-align: top !important;
  }
  .va-mi_pc {
    vertical-align: middle !important;
  }
  .va-bo_pc {
    vertical-align: bottom !important;
  }
}

/* テキストの太さ
------------------------------------------*/
.fw-bo {
  font-weight: bold;
}

.fw-no {
  font-weight: normal;
}

@media screen and (max-width: 790px) {
  .fw-bo_sp {
    font-weight: bold !important;
  }
  .fw-no_sp {
    font-weight: normal !important;
  }
}

@media screen and (min-width: 790px) {
  .fw-bo_pc {
    font-weight: bold !important;
  }
  .fw-no_pc {
    font-weight: normal !important;
  }
}

/* 一行ではみ出た部分を...
------------------------------------------*/
.to-el {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media screen and (max-width: 790px) {
  .to-el_sp {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

@media screen and (min-width: 790px) {
  .to-el_pc {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

/* 折り返しなし・はみ出たら非表示
------------------------------------------*/
.to-el {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media screen and (max-width: 790px) {
  .to-el_sp {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

@media screen and (min-width: 790px) {
  .to-el_pc {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

/*====================================================================================
余白の設定：top、right、bottom、left、topとbottom、leftとright
====================================================================================*/
/*------------------------------------------
paddingを与える
------------------------------------------*/
.pt0 {
  padding-top: 0px !important;
}

.pr0 {
  padding-right: 0px !important;
}

.pb0 {
  padding-bottom: 0px !important;
}

.pl0 {
  padding-left: 0px !important;
}

.ptb0 {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.plr0 {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.pt10 {
  padding-top: 10px !important;
}

.pr10 {
  padding-right: 10px !important;
}

.pb10 {
  padding-bottom: 10px !important;
}

.pl10 {
  padding-left: 10px !important;
}

.ptb10 {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}

.plr10 {
  padding-left: 10px !important;
  padding-right: 10px !important;
}

.pt20 {
  padding-top: 20px !important;
}

.pr20 {
  padding-right: 20px !important;
}

.pb20 {
  padding-bottom: 20px !important;
}

.pl20 {
  padding-left: 20px !important;
}

.ptb20 {
  padding-top: 20px !important;
  padding-bottom: 20px !important;
}

.plr20 {
  padding-left: 20px !important;
  padding-right: 20px !important;
}

.pt30 {
  padding-top: 30px !important;
}

.pr30 {
  padding-right: 30px !important;
}

.pb30 {
  padding-bottom: 30px !important;
}

.pl30 {
  padding-left: 30px !important;
}

.ptb30 {
  padding-top: 30px !important;
  padding-bottom: 30px !important;
}

.plr30 {
  padding-left: 30px !important;
  padding-right: 30px !important;
}

.pt40 {
  padding-top: 40px !important;
}

.pr40 {
  padding-right: 40px !important;
}

.pb40 {
  padding-bottom: 40px !important;
}

.pl40 {
  padding-left: 40px !important;
}

.ptb40 {
  padding-top: 40px !important;
  padding-bottom: 40px !important;
}

.plr40 {
  padding-left: 40px !important;
  padding-right: 40px !important;
}

.pt50 {
  padding-top: 50px !important;
}

.pr50 {
  padding-right: 50px !important;
}

.pb50 {
  padding-bottom: 50px !important;
}

.pl50 {
  padding-left: 50px !important;
}

.ptb50 {
  padding-top: 50px !important;
  padding-bottom: 50px !important;
}

.plr50 {
  padding-left: 50px !important;
  padding-right: 50px !important;
}

.pt60 {
  padding-top: 60px !important;
}

.pr60 {
  padding-right: 60px !important;
}

.pb60 {
  padding-bottom: 60px !important;
}

.pl60 {
  padding-left: 60px !important;
}

.ptb60 {
  padding-top: 60px !important;
  padding-bottom: 60px !important;
}

.plr60 {
  padding-left: 60px !important;
  padding-right: 60px !important;
}

.pt70 {
  padding-top: 70px !important;
}

.pr70 {
  padding-right: 70px !important;
}

.pb70 {
  padding-bottom: 70px !important;
}

.pl70 {
  padding-left: 70px !important;
}

.ptb70 {
  padding-top: 70px !important;
  padding-bottom: 70px !important;
}

.plr70 {
  padding-left: 70px !important;
  padding-right: 70px !important;
}

.pt80 {
  padding-top: 80px !important;
}

.pr80 {
  padding-right: 80px !important;
}

.pb80 {
  padding-bottom: 80px !important;
}

.pl80 {
  padding-left: 80px !important;
}

.ptb80 {
  padding-top: 80px !important;
  padding-bottom: 80px !important;
}

.plr80 {
  padding-left: 80px !important;
  padding-right: 80px !important;
}

.pt90 {
  padding-top: 90px !important;
}

.pr90 {
  padding-right: 90px !important;
}

.pb90 {
  padding-bottom: 90px !important;
}

.pl90 {
  padding-left: 90px !important;
}

.ptb90 {
  padding-top: 90px !important;
  padding-bottom: 90px !important;
}

.plr90 {
  padding-left: 90px !important;
  padding-right: 90px !important;
}

.pt100 {
  padding-top: 100px !important;
}

.pr100 {
  padding-right: 100px !important;
}

.pb100 {
  padding-bottom: 100px !important;
}

.pl100 {
  padding-left: 100px !important;
}

.ptb100 {
  padding-top: 100px !important;
  padding-bottom: 100px !important;
}

.plr100 {
  padding-left: 100px !important;
  padding-right: 100px !important;
}

.ptAuto {
  padding-top: auto !important;
}

.prAuto {
  padding-right: auto !important;
}

.pbAuto {
  padding-bottom: auto !important;
}

.plAuto {
  padding-left: auto !important;
}

.ptbAuto {
  padding-top: auto !important;
  padding-bottom: auto !important;
}

.plrAuto {
  padding-left: auto !important;
  padding-right: auto !important;
}

@media screen and (max-width: 790px) {
  .pt0_sp {
    padding-top: 0px;
  }
  .pr0_sp {
    padding-right: 0px;
  }
  .pb0_sp {
    padding-bottom: 0px;
  }
  .pl0_sp {
    padding-left: 0px;
  }
  .ptb0_sp {
    padding-top: 0px;
    padding-bottom: 0px;
  }
  .plr0_sp {
    padding-left: 0px;
    padding-right: 0px;
  }
  .pt10_sp {
    padding-top: 10px;
  }
  .pr10_sp {
    padding-right: 10px;
  }
  .pb10_sp {
    padding-bottom: 10px;
  }
  .pl10_sp {
    padding-left: 10px;
  }
  .ptb10_sp {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .plr10_sp {
    padding-left: 10px;
    padding-right: 10px;
  }
  .pt20_sp {
    padding-top: 20px;
  }
  .pr20_sp {
    padding-right: 20px;
  }
  .pb20_sp {
    padding-bottom: 20px;
  }
  .pl20_sp {
    padding-left: 20px;
  }
  .ptb20_sp {
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .plr20_sp {
    padding-left: 20px;
    padding-right: 20px;
  }
  .pt30_sp {
    padding-top: 30px;
  }
  .pr30_sp {
    padding-right: 30px;
  }
  .pb30_sp {
    padding-bottom: 30px;
  }
  .pl30_sp {
    padding-left: 30px;
  }
  .ptb30_sp {
    padding-top: 30px;
    padding-bottom: 30px;
  }
  .plr30_sp {
    padding-left: 30px;
    padding-right: 30px;
  }
  .pt40_sp {
    padding-top: 40px;
  }
  .pr40_sp {
    padding-right: 40px;
  }
  .pb40_sp {
    padding-bottom: 40px;
  }
  .pl40_sp {
    padding-left: 40px;
  }
  .ptb40_sp {
    padding-top: 40px;
    padding-bottom: 40px;
  }
  .plr40_sp {
    padding-left: 40px;
    padding-right: 40px;
  }
  .pt50_sp {
    padding-top: 50px;
  }
  .pr50_sp {
    padding-right: 50px;
  }
  .pb50_sp {
    padding-bottom: 50px;
  }
  .pl50_sp {
    padding-left: 50px;
  }
  .ptb50_sp {
    padding-top: 50px;
    padding-bottom: 50px;
  }
  .plr50_sp {
    padding-left: 50px;
    padding-right: 50px;
  }
  .pt60_sp {
    padding-top: 60px;
  }
  .pr60_sp {
    padding-right: 60px;
  }
  .pb60_sp {
    padding-bottom: 60px;
  }
  .pl60_sp {
    padding-left: 60px;
  }
  .ptb60_sp {
    padding-top: 60px;
    padding-bottom: 60px;
  }
  .plr60_sp {
    padding-left: 60px;
    padding-right: 60px;
  }
  .pt70_sp {
    padding-top: 70px;
  }
  .pr70_sp {
    padding-right: 70px;
  }
  .pb70_sp {
    padding-bottom: 70px;
  }
  .pl70_sp {
    padding-left: 70px;
  }
  .ptb70_sp {
    padding-top: 70px;
    padding-bottom: 70px;
  }
  .plr70_sp {
    padding-left: 70px;
    padding-right: 70px;
  }
  .pt80_sp {
    padding-top: 80px;
  }
  .pr80_sp {
    padding-right: 80px;
  }
  .pb80_sp {
    padding-bottom: 80px;
  }
  .pl80_sp {
    padding-left: 80px;
  }
  .ptb80_sp {
    padding-top: 80px;
    padding-bottom: 80px;
  }
  .plr80_sp {
    padding-left: 80px;
    padding-right: 80px;
  }
  .pt90_sp {
    padding-top: 90px;
  }
  .pr90_sp {
    padding-right: 90px;
  }
  .pb90_sp {
    padding-bottom: 90px;
  }
  .pl90_sp {
    padding-left: 90px;
  }
  .ptb90_sp {
    padding-top: 90px;
    padding-bottom: 90px;
  }
  .plr90_sp {
    padding-left: 90px;
    padding-right: 90px;
  }
  .pt100_sp {
    padding-top: 100px;
  }
  .pr100_sp {
    padding-right: 100px;
  }
  .pb100_sp {
    padding-bottom: 100px;
  }
  .pl100_sp {
    padding-left: 100px;
  }
  .ptb100_sp {
    padding-top: 100px;
    padding-bottom: 100px;
  }
  .plr100_sp {
    padding-left: 100px;
    padding-right: 100px;
  }
  .ptAuto_sp {
    padding-top: auto;
  }
  .prAuto_sp {
    padding-right: auto;
  }
  .pbAuto_sp {
    padding-bottom: auto;
  }
  .plAuto_sp {
    padding-left: auto;
  }
  .ptbAuto_sp {
    padding-top: auto;
    padding-bottom: auto;
  }
  .plrAuto_sp {
    padding-left: auto;
    padding-right: auto;
  }
}

@media screen and (min-width: 790px) {
  .pt0_pc {
    padding-top: 0px;
  }
  .pr0_pc {
    padding-right: 0px;
  }
  .pb0_pc {
    padding-bottom: 0px;
  }
  .pl0_pc {
    padding-left: 0px;
  }
  .ptb0_pc {
    padding-top: 0px;
    padding-bottom: 0px;
  }
  .plr0_pc {
    padding-left: 0px;
    padding-right: 0px;
  }
  .pt10_pc {
    padding-top: 10px;
  }
  .pr10_pc {
    padding-right: 10px;
  }
  .pb10_pc {
    padding-bottom: 10px;
  }
  .pl10_pc {
    padding-left: 10px;
  }
  .ptb10_pc {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .plr10_pc {
    padding-left: 10px;
    padding-right: 10px;
  }
  .pt20_pc {
    padding-top: 20px;
  }
  .pr20_pc {
    padding-right: 20px;
  }
  .pb20_pc {
    padding-bottom: 20px;
  }
  .pl20_pc {
    padding-left: 20px;
  }
  .ptb20_pc {
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .plr20_pc {
    padding-left: 20px;
    padding-right: 20px;
  }
  .pt30_pc {
    padding-top: 30px;
  }
  .pr30_pc {
    padding-right: 30px;
  }
  .pb30_pc {
    padding-bottom: 30px;
  }
  .pl30_pc {
    padding-left: 30px;
  }
  .ptb30_pc {
    padding-top: 30px;
    padding-bottom: 30px;
  }
  .plr30_pc {
    padding-left: 30px;
    padding-right: 30px;
  }
  .pt40_pc {
    padding-top: 40px;
  }
  .pr40_pc {
    padding-right: 40px;
  }
  .pb40_pc {
    padding-bottom: 40px;
  }
  .pl40_pc {
    padding-left: 40px;
  }
  .ptb40_pc {
    padding-top: 40px;
    padding-bottom: 40px;
  }
  .plr40_pc {
    padding-left: 40px;
    padding-right: 40px;
  }
  .pt50_pc {
    padding-top: 50px;
  }
  .pr50_pc {
    padding-right: 50px;
  }
  .pb50_pc {
    padding-bottom: 50px;
  }
  .pl50_pc {
    padding-left: 50px;
  }
  .ptb50_pc {
    padding-top: 50px;
    padding-bottom: 50px;
  }
  .plr50_pc {
    padding-left: 50px;
    padding-right: 50px;
  }
  .pt60_pc {
    padding-top: 60px;
  }
  .pr60_pc {
    padding-right: 60px;
  }
  .pb60_pc {
    padding-bottom: 60px;
  }
  .pl60_pc {
    padding-left: 60px;
  }
  .ptb60_pc {
    padding-top: 60px;
    padding-bottom: 60px;
  }
  .plr60_pc {
    padding-left: 60px;
    padding-right: 60px;
  }
  .pt70_pc {
    padding-top: 70px;
  }
  .pr70_pc {
    padding-right: 70px;
  }
  .pb70_pc {
    padding-bottom: 70px;
  }
  .pl70_pc {
    padding-left: 70px;
  }
  .ptb70_pc {
    padding-top: 70px;
    padding-bottom: 70px;
  }
  .plr70_pc {
    padding-left: 70px;
    padding-right: 70px;
  }
  .pt80_pc {
    padding-top: 80px;
  }
  .pr80_pc {
    padding-right: 80px;
  }
  .pb80_pc {
    padding-bottom: 80px;
  }
  .pl80_pc {
    padding-left: 80px;
  }
  .ptb80_pc {
    padding-top: 80px;
    padding-bottom: 80px;
  }
  .plr80_pc {
    padding-left: 80px;
    padding-right: 80px;
  }
  .pt90_pc {
    padding-top: 90px;
  }
  .pr90_pc {
    padding-right: 90px;
  }
  .pb90_pc {
    padding-bottom: 90px;
  }
  .pl90_pc {
    padding-left: 90px;
  }
  .ptb90_pc {
    padding-top: 90px;
    padding-bottom: 90px;
  }
  .plr90_pc {
    padding-left: 90px;
    padding-right: 90px;
  }
  .pt100_pc {
    padding-top: 100px;
  }
  .pr100_pc {
    padding-right: 100px;
  }
  .pb100_pc {
    padding-bottom: 100px;
  }
  .pl100_pc {
    padding-left: 100px;
  }
  .ptb100_pc {
    padding-top: 100px;
    padding-bottom: 100px;
  }
  .plr100_pc {
    padding-left: 100px;
    padding-right: 100px;
  }
  .ptAuto_pc {
    padding-top: auto;
  }
  .prAuto_pc {
    padding-right: auto;
  }
  .pbAuto_pc {
    padding-bottom: auto;
  }
  .plAuto_pc {
    padding-left: auto;
  }
  .ptbAuto_pc {
    padding-top: auto;
    padding-bottom: auto;
  }
  .plrAuto_pc {
    padding-left: auto;
    padding-right: auto;
  }
}

/*------------------------------------------
marginを与える
------------------------------------------*/
.mt0 {
  margin-top: 0px !important;
}

.mr0 {
  margin-right: 0px !important;
}

.mb0 {
  margin-bottom: 0px !important;
}

.ml0 {
  margin-left: 0px !important;
}

.mtb0 {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}

.mlr0 {
  margin-left: 0px !important;
  margin-right: 0px !important;
}

.mt10 {
  margin-top: 10px !important;
}

.mr10 {
  margin-right: 10px !important;
}

.mb10 {
  margin-bottom: 10px !important;
}

.ml10 {
  margin-left: 10px !important;
}

.mtb10 {
  margin-top: 10px !important;
  margin-bottom: 10px !important;
}

.mlr10 {
  margin-left: 10px !important;
  margin-right: 10px !important;
}

.mt20 {
  margin-top: 20px !important;
}

.mr20 {
  margin-right: 20px !important;
}

.mb20 {
  margin-bottom: 20px !important;
}

.ml20 {
  margin-left: 20px !important;
}

.mtb20 {
  margin-top: 20px !important;
  margin-bottom: 20px !important;
}

.mlr20 {
  margin-left: 20px !important;
  margin-right: 20px !important;
}

.mt30 {
  margin-top: 30px !important;
}

.mr30 {
  margin-right: 30px !important;
}

.mb30 {
  margin-bottom: 30px !important;
}

.ml30 {
  margin-left: 30px !important;
}

.mtb30 {
  margin-top: 30px !important;
  margin-bottom: 30px !important;
}

.mlr30 {
  margin-left: 30px !important;
  margin-right: 30px !important;
}

.mt40 {
  margin-top: 40px !important;
}

.mr40 {
  margin-right: 40px !important;
}

.mb40 {
  margin-bottom: 40px !important;
}

.ml40 {
  margin-left: 40px !important;
}

.mtb40 {
  margin-top: 40px !important;
  margin-bottom: 40px !important;
}

.mlr40 {
  margin-left: 40px !important;
  margin-right: 40px !important;
}

.mt50 {
  margin-top: 50px !important;
}

.mr50 {
  margin-right: 50px !important;
}

.mb50 {
  margin-bottom: 50px !important;
}

.ml50 {
  margin-left: 50px !important;
}

.mtb50 {
  margin-top: 50px !important;
  margin-bottom: 50px !important;
}

.mlr50 {
  margin-left: 50px !important;
  margin-right: 50px !important;
}

.mt60 {
  margin-top: 60px !important;
}

.mr60 {
  margin-right: 60px !important;
}

.mb60 {
  margin-bottom: 60px !important;
}

.ml60 {
  margin-left: 60px !important;
}

.mtb60 {
  margin-top: 60px !important;
  margin-bottom: 60px !important;
}

.mlr60 {
  margin-left: 60px !important;
  margin-right: 60px !important;
}

.mt70 {
  margin-top: 70px !important;
}

.mr70 {
  margin-right: 70px !important;
}

.mb70 {
  margin-bottom: 70px !important;
}

.ml70 {
  margin-left: 70px !important;
}

.mtb70 {
  margin-top: 70px !important;
  margin-bottom: 70px !important;
}

.mlr70 {
  margin-left: 70px !important;
  margin-right: 70px !important;
}

.mt80 {
  margin-top: 80px !important;
}

.mr80 {
  margin-right: 80px !important;
}

.mb80 {
  margin-bottom: 80px !important;
}

.ml80 {
  margin-left: 80px !important;
}

.mtb80 {
  margin-top: 80px !important;
  margin-bottom: 80px !important;
}

.mlr80 {
  margin-left: 80px !important;
  margin-right: 80px !important;
}

.mt90 {
  margin-top: 90px !important;
}

.mr90 {
  margin-right: 90px !important;
}

.mb90 {
  margin-bottom: 90px !important;
}

.ml90 {
  margin-left: 90px !important;
}

.mtb90 {
  margin-top: 90px !important;
  margin-bottom: 90px !important;
}

.mlr90 {
  margin-left: 90px !important;
  margin-right: 90px !important;
}

.mt100 {
  margin-top: 100px !important;
}

.mr100 {
  margin-right: 100px !important;
}

.mb100 {
  margin-bottom: 100px !important;
}

.ml100 {
  margin-left: 100px !important;
}

.mtb100 {
  margin-top: 100px !important;
  margin-bottom: 100px !important;
}

.mlr100 {
  margin-left: 100px !important;
  margin-right: 100px !important;
}

.mtAuto {
  margin-top: auto !important;
}

.mrAuto {
  margin-right: auto !important;
}

.mbAuto {
  margin-bottom: auto !important;
}

.mlAuto {
  margin-left: auto !important;
}

.mtbAuto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.mlrAuto {
  margin-left: auto !important;
  margin-right: auto !important;
}

@media screen and (max-width: 790px) {
  .mt0_sp {
    margin-top: 0px;
  }
  .mr0_sp {
    margin-right: 0px;
  }
  .mb0_sp {
    margin-bottom: 0px;
  }
  .ml0_sp {
    margin-left: 0px;
  }
  .mtb0_sp {
    margin-top: 0px;
    margin-bottom: 0px;
  }
  .mlr0_sp {
    margin-left: 0px;
    margin-right: 0px;
  }
  .mt10_sp {
    margin-top: 10px;
  }
  .mr10_sp {
    margin-right: 10px;
  }
  .mb10_sp {
    margin-bottom: 10px;
  }
  .ml10_sp {
    margin-left: 10px;
  }
  .mtb10_sp {
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .mlr10_sp {
    margin-left: 10px;
    margin-right: 10px;
  }
  .mt20_sp {
    margin-top: 20px;
  }
  .mr20_sp {
    margin-right: 20px;
  }
  .mb20_sp {
    margin-bottom: 20px;
  }
  .ml20_sp {
    margin-left: 20px;
  }
  .mtb20_sp {
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .mlr20_sp {
    margin-left: 20px;
    margin-right: 20px;
  }
  .mt30_sp {
    margin-top: 30px;
  }
  .mr30_sp {
    margin-right: 30px;
  }
  .mb30_sp {
    margin-bottom: 30px;
  }
  .ml30_sp {
    margin-left: 30px;
  }
  .mtb30_sp {
    margin-top: 30px;
    margin-bottom: 30px;
  }
  .mlr30_sp {
    margin-left: 30px;
    margin-right: 30px;
  }
  .mt40_sp {
    margin-top: 40px;
  }
  .mr40_sp {
    margin-right: 40px;
  }
  .mb40_sp {
    margin-bottom: 40px;
  }
  .ml40_sp {
    margin-left: 40px;
  }
  .mtb40_sp {
    margin-top: 40px;
    margin-bottom: 40px;
  }
  .mlr40_sp {
    margin-left: 40px;
    margin-right: 40px;
  }
  .mt50_sp {
    margin-top: 50px;
  }
  .mr50_sp {
    margin-right: 50px;
  }
  .mb50_sp {
    margin-bottom: 50px;
  }
  .ml50_sp {
    margin-left: 50px;
  }
  .mtb50_sp {
    margin-top: 50px;
    margin-bottom: 50px;
  }
  .mlr50_sp {
    margin-left: 50px;
    margin-right: 50px;
  }
  .mt60_sp {
    margin-top: 60px;
  }
  .mr60_sp {
    margin-right: 60px;
  }
  .mb60_sp {
    margin-bottom: 60px;
  }
  .ml60_sp {
    margin-left: 60px;
  }
  .mtb60_sp {
    margin-top: 60px;
    margin-bottom: 60px;
  }
  .mlr60_sp {
    margin-left: 60px;
    margin-right: 60px;
  }
  .mt70_sp {
    margin-top: 70px;
  }
  .mr70_sp {
    margin-right: 70px;
  }
  .mb70_sp {
    margin-bottom: 70px;
  }
  .ml70_sp {
    margin-left: 70px;
  }
  .mtb70_sp {
    margin-top: 70px;
    margin-bottom: 70px;
  }
  .mlr70_sp {
    margin-left: 70px;
    margin-right: 70px;
  }
  .mt80_sp {
    margin-top: 80px;
  }
  .mr80_sp {
    margin-right: 80px;
  }
  .mb80_sp {
    margin-bottom: 80px;
  }
  .ml80_sp {
    margin-left: 80px;
  }
  .mtb80_sp {
    margin-top: 80px;
    margin-bottom: 80px;
  }
  .mlr80_sp {
    margin-left: 80px;
    margin-right: 80px;
  }
  .mt90_sp {
    margin-top: 90px;
  }
  .mr90_sp {
    margin-right: 90px;
  }
  .mb90_sp {
    margin-bottom: 90px;
  }
  .ml90_sp {
    margin-left: 90px;
  }
  .mtb90_sp {
    margin-top: 90px;
    margin-bottom: 90px;
  }
  .mlr90_sp {
    margin-left: 90px;
    margin-right: 90px;
  }
  .mt100_sp {
    margin-top: 100px;
  }
  .mr100_sp {
    margin-right: 100px;
  }
  .mb100_sp {
    margin-bottom: 100px;
  }
  .ml100_sp {
    margin-left: 100px;
  }
  .mtb100_sp {
    margin-top: 100px;
    margin-bottom: 100px;
  }
  .mlr100_sp {
    margin-left: 100px;
    margin-right: 100px;
  }
  .mtAuto_sp {
    margin-top: auto;
  }
  .mrAuto_sp {
    margin-right: auto;
  }
  .mbAuto_sp {
    margin-bottom: auto;
  }
  .mlAuto_sp {
    margin-left: auto;
  }
  .mtbAuto_sp {
    margin-top: auto;
    margin-bottom: auto;
  }
  .mlrAuto_sp {
    margin-left: auto;
    margin-right: auto;
  }
}

@media screen and (min-width: 790px) {
  .mt0_pc {
    margin-top: 0px;
  }
  .mr0_pc {
    margin-right: 0px;
  }
  .mb0_pc {
    margin-bottom: 0px;
  }
  .ml0_pc {
    margin-left: 0px;
  }
  .mtb0_pc {
    margin-top: 0px;
    margin-bottom: 0px;
  }
  .mlr0_pc {
    margin-left: 0px;
    margin-right: 0px;
  }
  .mt10_pc {
    margin-top: 10px;
  }
  .mr10_pc {
    margin-right: 10px;
  }
  .mb10_pc {
    margin-bottom: 10px;
  }
  .ml10_pc {
    margin-left: 10px;
  }
  .mtb10_pc {
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .mlr10_pc {
    margin-left: 10px;
    margin-right: 10px;
  }
  .mt20_pc {
    margin-top: 20px;
  }
  .mr20_pc {
    margin-right: 20px;
  }
  .mb20_pc {
    margin-bottom: 20px;
  }
  .ml20_pc {
    margin-left: 20px;
  }
  .mtb20_pc {
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .mlr20_pc {
    margin-left: 20px;
    margin-right: 20px;
  }
  .mt30_pc {
    margin-top: 30px;
  }
  .mr30_pc {
    margin-right: 30px;
  }
  .mb30_pc {
    margin-bottom: 30px;
  }
  .ml30_pc {
    margin-left: 30px;
  }
  .mtb30_pc {
    margin-top: 30px;
    margin-bottom: 30px;
  }
  .mlr30_pc {
    margin-left: 30px;
    margin-right: 30px;
  }
  .mt40_pc {
    margin-top: 40px;
  }
  .mr40_pc {
    margin-right: 40px;
  }
  .mb40_pc {
    margin-bottom: 40px;
  }
  .ml40_pc {
    margin-left: 40px;
  }
  .mtb40_pc {
    margin-top: 40px;
    margin-bottom: 40px;
  }
  .mlr40_pc {
    margin-left: 40px;
    margin-right: 40px;
  }
  .mt50_pc {
    margin-top: 50px;
  }
  .mr50_pc {
    margin-right: 50px;
  }
  .mb50_pc {
    margin-bottom: 50px;
  }
  .ml50_pc {
    margin-left: 50px;
  }
  .mtb50_pc {
    margin-top: 50px;
    margin-bottom: 50px;
  }
  .mlr50_pc {
    margin-left: 50px;
    margin-right: 50px;
  }
  .mt60_pc {
    margin-top: 60px;
  }
  .mr60_pc {
    margin-right: 60px;
  }
  .mb60_pc {
    margin-bottom: 60px;
  }
  .ml60_pc {
    margin-left: 60px;
  }
  .mtb60_pc {
    margin-top: 60px;
    margin-bottom: 60px;
  }
  .mlr60_pc {
    margin-left: 60px;
    margin-right: 60px;
  }
  .mt70_pc {
    margin-top: 70px;
  }
  .mr70_pc {
    margin-right: 70px;
  }
  .mb70_pc {
    margin-bottom: 70px;
  }
  .ml70_pc {
    margin-left: 70px;
  }
  .mtb70_pc {
    margin-top: 70px;
    margin-bottom: 70px;
  }
  .mlr70_pc {
    margin-left: 70px;
    margin-right: 70px;
  }
  .mt80_pc {
    margin-top: 80px;
  }
  .mr80_pc {
    margin-right: 80px;
  }
  .mb80_pc {
    margin-bottom: 80px;
  }
  .ml80_pc {
    margin-left: 80px;
  }
  .mtb80_pc {
    margin-top: 80px;
    margin-bottom: 80px;
  }
  .mlr80_pc {
    margin-left: 80px;
    margin-right: 80px;
  }
  .mt90_pc {
    margin-top: 90px;
  }
  .mr90_pc {
    margin-right: 90px;
  }
  .mb90_pc {
    margin-bottom: 90px;
  }
  .ml90_pc {
    margin-left: 90px;
  }
  .mtb90_pc {
    margin-top: 90px;
    margin-bottom: 90px;
  }
  .mlr90_pc {
    margin-left: 90px;
    margin-right: 90px;
  }
  .mt100_pc {
    margin-top: 100px;
  }
  .mr100_pc {
    margin-right: 100px;
  }
  .mb100_pc {
    margin-bottom: 100px;
  }
  .ml100_pc {
    margin-left: 100px;
  }
  .mtb100_pc {
    margin-top: 100px;
    margin-bottom: 100px;
  }
  .mlr100_pc {
    margin-left: 100px;
    margin-right: 100px;
  }
  .mtAuto_pc {
    margin-top: auto;
  }
  .mrAuto_pc {
    margin-right: auto;
  }
  .mbAuto_pc {
    margin-bottom: auto;
  }
  .mlAuto_pc {
    margin-left: auto;
  }
  .mtbAuto_pc {
    margin-top: auto;
    margin-bottom: auto;
  }
  .mlrAuto_pc {
    margin-left: auto;
    margin-right: auto;
  }
}

/*====================================================================================
限定
====================================================================================*/
@media screen and (max-width: 790px) {
  .pc {
    display: none !important;
  }
}

@media screen and (min-width: 790px) {
  .sp {
    display: none !important;
  }
}

/*====================================================================================
横並びの設定
====================================================================================*/
.colBox {
  display: table;
  table-layout: fixed;
  width: 100%;
}

.colBox .col-l, .colBox .col-r {
  display: table-cell;
}

/*------------------------------------------
解除
------------------------------------------*/
.cf:after {
  content: "";
  display: block;
  width: 100%;
  height: 0;
  clear: both;
}

@media screen and (max-width: 790px) {
  .cf_sp:after {
    content: "";
    display: block;
    width: 100%;
    height: 0;
    clear: both;
  }
}

@media screen and (min-width: 790px) {
  .cf_pc:after {
    content: "";
    display: block;
    width: 100%;
    height: 0;
    clear: both;
  }
}

.wf-notosansjapanese {
  font-family: "Noto Sans Japanese";
}

.wf-mplus1p {
  font-family: "Mplus 1p";
}

.wf-roundedmplus1c {
  font-family: "Rounded Mplus 1c";
}

.wf-kokoro {
  font-family: "Kokoro";
}

.wf-sawarabimincho {
  font-family: "Sawarabi Mincho";
}

.wf-nikukyu {
  font-family: "Nikukyu";
}

.wf-nicomoji {
  font-family: "Nico Moji";
}

body {
  position: relative;
  background: linear-gradient(45deg, #c8d4ff, #ccc8f6 50%, #d0ffed);
  width: 100%;
  height: 100vh;
}

.bgWh {
  background: rgba(255, 255, 255, 0.9);
}

.areaStyle01 {
  padding-left: 10px;
  padding-right: 10px;
}

.inner {
  padding: 5px 10px;
}

/* 内padding10px 下margin10px*/
article section {
  margin-bottom: 10px;
}

/*text
---------------------------------------*/
#index h1 a {
  font-size: 24px;
}

/*areaごと
---------------------------------------*/
.header {
  padding-top: 10px;
  padding-bottom: 10px;
}

#index .header {
  padding-top: 15px;
  padding-bottom: 15px;
}

.menuList {
  position: relative;
  display: block;
  z-index: 999;
}

.menuList .menu01_content {
  position: absolute;
}

.menuList.top {
  position: fixed;
  top: 0;
  display: none;
}

.menu01 {
  margin-top: 2px;
}

.menu01 > .col-l, .menu01 > .col-r {
  width: 50%;
}

.menu01 > .col-l .menu01_item {
  margin-right: 1px;
  padding: 10px 5px;
}

.menu01 > .col-r .menu01_item {
  margin-left: 1px;
  padding: 10px 5px;
}

.menu01 .menu01_item p {
  text-align: center;
}

.menu01_content {
  width: 100%;
  z-index: 999;
}

.menu01_content ul {
  list-style: none;
}

.menu01_content > * {
  display: none;
  width: 100%;
  margin-top: 1px;
}

.menu01_content > * li a {
  display: block;
  padding: 8px;
  background: rgba(255, 255, 255, 0.9);
}

.menu01_content > * li:first-child {
  border-top: 1px solid #e2e2e2;
}

.menu01_content > * li:not(:last-child) {
  border-bottom: 1px solid #e2e2e2;
}

.menuBottom .menuList .menu01_content {
  bottom: 42px;
}

/*article内
---------------------------------------*/
.itemThumb {
  overflow: hidden;
  position: relative;
}

.itemThumb img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.itemThumb img.yoko {
  width: auto;
  height: 100%;
}

.itemThumb img.tate {
  height: auto;
  width: 100%;
}

.item {
  margin-bottom: 10px;
}

.item .itemThumb {
  width: 100%;
  height: 200px;
}

.item .itemThumb img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.item .itemThumb img.yoko {
  width: 100%;
  height: auto;
}

.item .itemThumb img.tate {
  height: 100%;
  width: auto;
}

.itemTitle {
  font-size: 18px;
  font-weight: 600;
  line-height: 1.4;
  padding: 5px 0;
  border-bottom: 1px solid #B2B2B2;
}

.itemInfo {
  margin-bottom: 5px;
  height: 2rem;
  position: relative;
}

.itemInfo > * {
  display: inline;
  position: absolute;
}

.itemInfo > *:first-child {
  left: 0;
}

.itemInfo > *:last-child {
  right: 0;
}

.itemCategory, .itemDay, .itemTag {
  font-size: 12px;
  color: #666666;
  line-height: 1rem;
  padding: 1rem 0;
}

.itemCategory {
  vertical-align: middle;
}

.itemCategory:before {
  content: "";
  display: inline-block;
  width: 12px;
  height: 19px;
  background: url(../images/icon_category.svg) no-repeat;
  background-size: 12px 19px;
  margin-right: 0.5rem;
}

.itemTag {
  vertical-align: middle;
}

.itemTag:before {
  content: "";
  display: inline-block;
  width: 12px;
  height: 19px;
  background: url(../images/icon_tag.svg) no-repeat;
  background-size: 12px 19px;
  margin-right: 0.5rem;
}

.itemTag > *:not(:first-child) {
  margin-left: 0.8rem;
}

/*post
---------------------------------------*/
#post article section {
  padding-bottom: 15px;
}

#post article .titleArea {
  padding-bottom: 30px;
}

#post article .itemTitle {
  text-align: center;
  padding: 20px;
}

#post article figure {
  width: 100%;
  margin-left: 0;
  margin-right: 0;
}

#post article img {
  width: 100%;
  height: auto;
}

#post article .content p {
  line-height: 2.4rem;
}

#post article .content p:not(:last-child) {
  margin-bottom: 15px;
}

#post .menuBottom {
  margin-bottom: 15px;
}

/*menuBottom
---------------------------------------*/
.menu-foot div {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.menu-foot div > * {
  text-align: center;
}

.menu-foot div > * a {
  display: inline-block;
  padding: 14px;
  font-size: 12px;
}

.arrow-l a:before {
  content: "";
  display: inline-block;
  width: 8px;
  height: 8px;
  border-right: 1px solid #ababab;
  border-top: 1px solid #ababab;
  transform: rotate(-135deg);
}

.arrow-r a:after {
  content: "";
  display: inline-block;
  width: 8px;
  height: 8px;
  border-right: 1px solid #ababab;
  border-top: 1px solid #ababab;
  transform: rotate(45deg);
}

/* footer
------------------------------------------*/
footer {
  width: 100%;
  height: 30px;
  margin-top: 20px;
}

footer > * {
  text-align: center;
  padding: 5px;
}

footer small {
  color: #666666;
  font-size: 12px;
}

@media screen and (min-width: 790px) {
  .areaStyle01 {
    width: 800px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 0;
    padding-right: 0;
  }
  section .item:nth-child(2n-1) {
    float: left;
  }
  section .item:nth-child(2n) {
    float: right;
  }
  section .item {
    width: 380px;
  }
  section .item .itemThumb {
    height: 200px;
  }
}

@charset "UTF-8";

//ブレイクポイントの設定
$breakPoint : 790px;
$pc_maxWidth: 1200px;
$pc_minWidth: 1200px;
$sp_maxWidth: 768px;
$sp_minWidth: 320px;

//カラーの設定
$color_black01: #303030;
$color_black02: #ababab;
$color_gray01: #666666;
$color_gray02: #B2B2B2;

//フォント
$font_Go_jp01 : "ヒラギノ角ゴシック", "Hiragino Sans", "ヒラギノ角ゴ ProN W3", "HiraKakuProN-W3", "メイリオ", Meiryo, "ＭＳ Ｐゴシック", sans-serif;
$font_Go_jp02 : "游ゴシック体","YuGothic", "ヒラギノ角ゴシック", "Hiragino Sans", "ヒラギノ角ゴ ProN W3", "HiraKakuProN-W3", "メイリオ", Meiryo, "ＭＳ Ｐゴシック", sans-serif;
$font_Min_jp01: "ヒラギノ明朝 ProN W3", "HiraMinProN-W3", "HG明朝E", "ＭＳ Ｐ明朝", "MS PMincho", "MS 明朝", serif;
$font_Min_jp02: "游明朝体","YuMincho", "ヒラギノ明朝 ProN W3", "HiraMinProN-W3", "HG明朝E", "ＭＳ Ｐ明朝", "MS PMincho", "MS 明朝", serif;

//webフォント
//.wf-mplus1p { font-family: "Mplus 1p"; } //<link href="https://fonts.googleapis.com/earlyaccess/mplus1p.css" rel="stylesheet" />
@charset "UTF-8";

//------------------------------------------
//日本語フォント
//------------------------------------------
.wf-notosansjapanese { font-family: "Noto Sans Japanese"; } //<link href="https://fonts.googleapis.com/earlyaccess/notosansjapanese.css" rel="stylesheet" />
.wf-mplus1p { font-family: "Mplus 1p"; } //<link href="https://fonts.googleapis.com/earlyaccess/mplus1p.css" rel="stylesheet" />
.wf-roundedmplus1c { font-family: "Rounded Mplus 1c"; } //<link href="https://fonts.googleapis.com/earlyaccess/roundedmplus1c.css" rel="stylesheet" />
.wf-kokoro { font-family: "Kokoro"; } //<link href="https://fonts.googleapis.com/earlyaccess/kokoro.css" rel="stylesheet" />
.wf-sawarabimincho { font-family: "Sawarabi Mincho"; } //<link href="https://fonts.googleapis.com/earlyaccess/sawarabimincho.css" rel="stylesheet" />
.wf-nikukyu { font-family: "Nikukyu"; } //<link href="https://fonts.googleapis.com/earlyaccess/nikukyu.css" rel="stylesheet" />
.wf-nicomoji { font-family: "Nico Moji"; } //<link href="https://fonts.googleapis.com/earlyaccess/nicomoji.css" rel="stylesheet" />

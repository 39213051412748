@charset "UTF-8";
@import "sanitize";
@import "extend";
@import "mixin";
@import "common";
@import "font";

@import "sp/common";

@media screen and(min-width: $breakPoint){ 
  @import "pc/common";
}
body{
	position: relative;
  background: linear-gradient(45deg, #c8d4ff, #ccc8f6 50%, #d0ffed);
  width: 100%;
  height: 100vh;
}

.bgWh{
  background: rgba(255,255,255,0.9);
}

.areaStyle01{
  padding-left: 10px;
  padding-right: 10px;
}

.inner{padding: 5px 10px;}

/* 内padding10px 下margin10px*/
article section{margin-bottom: 10px;}

/*text
---------------------------------------*/
#index h1 a{font-size: 24px;}

/*areaごと
---------------------------------------*/
.header{
	padding-top: 10px;
	padding-bottom: 10px;
}

#index .header{
	padding-top: 15px;
	padding-bottom: 15px;
}

.menuList{
	position: relative;
	display: block;
	z-index: 999;
	.menu01_content{position: absolute;}
	&.top{position: fixed; top: 0; display: none;}
}

.menu01{
	margin-top: 2px;
	>.col-l, >.col-r{width: 50%;}
	>.col-l .menu01_item{
		margin-right: 1px;
		padding: 10px 5px;
	}
	>.col-r .menu01_item{
		margin-left: 1px;
		padding: 10px 5px;
	}
	.menu01_item{
		p{text-align: center;}
	}
		
}

.menu01_content{
	width: 100%;
	z-index: 999;
	ul{list-style: none;}
	> *{
		display: none;
		width: 100%;
		margin-top: 1px;
		li{
			a{
				display: block;
				padding: 8px;
				background: rgba(255,255,255,0.9);
			}
			&:first-child{border-top:1px solid #e2e2e2;}
			&:not(:last-child){border-bottom:1px solid #e2e2e2;}		
		}
	}
}

.menuBottom{
	.menuList{
		.menu01_content{bottom: 42px;}
	}
}


/*article内
---------------------------------------*/
.itemThumb{
		overflow: hidden;
		position: relative;
		img{
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			&.yoko {width: auto; height: 100%;}
			&.tate {height: auto; width: 100%;}
		}
	}

.item{
	margin-bottom: 10px;
	.itemThumb{
		width: 100%;
		height: 200px;
		img{
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			&.yoko {width: 100%; height: auto;}
			&.tate {height: 100%; width: auto;}
		}
	}
}

.itemTitle{
			font-size: 18px;
			font-weight: 600;
			line-height: 1.4;
			padding: 5px 0;
			border-bottom: 1px solid $color_gray02;
		}

.itemInfo{
	margin-bottom: 5px;
	height: 2rem;
	position: relative;
		> *{
			display: inline;
			position: absolute;
			&:first-child{left: 0;}
			&:last-child{right: 0;}
		}
	}

	.itemCategory, .itemDay, .itemTag{
		font-size: 12px;
		color: $color-gray01;
		line-height: 1rem;
		padding: 1rem 0;
	}

	.itemCategory{
		vertical-align: middle;
		&:before{
			content: "";
			display: inline-block;
			width:12px ; height: 19px;
			background: url(../images/icon_category.svg) no-repeat;
			background-size: 12px 19px;
			margin-right: 0.5rem;
		}
	}

	.itemTag{
		vertical-align: middle;
		&:before{
			content: "";
			display: inline-block;
			width:12px ; height: 19px;
			background: url(../images/icon_tag.svg) no-repeat;
			background-size: 12px 19px;
			margin-right: 0.5rem;
		}
		> *:not(:first-child){margin-left: 0.8rem;}
	}

/*post
---------------------------------------*/
#post{
	article{
		section{padding-bottom: 15px;}
		.titleArea{padding-bottom: 30px;}
		.itemTitle{text-align: center; padding:20px;}
		figure{width: 100%; margin-left: 0; margin-right: 0}
		img{width: 100%; height: auto;}
		.content{
			p{line-height: 2.4rem;}
			p:not(:last-child){margin-bottom: 15px;}
		}
	}
	.menuBottom{margin-bottom: 15px;}
}


/*menuBottom
---------------------------------------*/
.menu-foot{
	div{
		display: flex;
		justify-content: space-between;
		width: 100%;
		> *{
			text-align: center;
			a{
				display: inline-block;
				padding: 14px;
				font-size: 12px;
			}
		}
	}
}

.arrow-l a:before{
	content: "";
	display: inline-block;
	width: 8px; height: 8px;
	border-right: 1px solid $color-black02;
	border-top: 1px solid $color-black02;
	transform: rotate(-135deg);
}

.arrow-r a:after{
	content: "";
	display: inline-block;
	width: 8px; height: 8px;
	border-right: 1px solid $color-black02;
	border-top: 1px solid $color-black02;
	transform: rotate(45deg);
}

/* footer
------------------------------------------*/
footer{
	width: 100%; height: 30px;
	margin-top: 20px;
	> *{
		text-align: center;
		padding: 5px;
	}
	small{
		color: $color-gray01;
		font-size: 12px;
	}
}


@charset "UTF-8";


/*====================================================================================
エリアの設定
====================================================================================*/
html,body {overflow-x: hidden;　position: relative;}

/*====================================================================================
テキスト
====================================================================================*/
body {-webkit-font-smoothing: antialiased;}

/*------------------------------------------
基本フォント
------------------------------------------*/
h1, h2, h3, h4, h5, h6, p, blockquote, pre,abbr, address, cite, code,dt, dd, li,fieldset, form,
label, legend,table, caption, tbody, tfoot, thead, tr, th, td,article, aside, canvas, details, figcaption, figure {
	color: $color_black01;
	font-size: 14px;
	font-weight: 300;
	font-family: $font_Go_jp02;
	text-align : left;
	line-height : 20px;
	letter-spacing : 0;
	word-wrap: break-word;
}

/*------------------------------------------
テキストスタイル
------------------------------------------*/
/* リンク
------------------------------------------*/
a{color: $color_black01;}

.txtOverF{
	overflow: hidden;
white-space: nowrap;
text-overflow: ellipsis;
}
/* 行揃え
------------------------------------------*/
.ta-le {text-align: left !important;}
.ta-ce {text-align: center !important;}
.ta-ri {text-align: right !important;}

@media screen and(max-width: $breakPoint){ .ta-le_sp {text-align: left !important;} .ta-ce_sp {text-align: center !important;} .ta-ri_sp {text-align: right !important;} }
@media screen and(min-width: $breakPoint){ .ta-le_pc {text-align: left !important;} .ta-ce_pc {text-align: center !important;} .ta-ri_pc {text-align: right !important;} }

/* 縦揃え（vertiacl-align
------------------------------------------*/
.va-to {vertical-align: top !important;}
.va-mi {vertical-align: middle !important;}
.va-bo {vertical-align: bottom !important;}

@media screen and(max-width: $breakPoint){ .va-to_sp {vertical-align: top !important;} .va-mi_sp {vertical-align: middle !important;} .va-bo_sp {vertical-align: bottom !important;} }
@media screen and(min-width: $breakPoint){ .va-to_pc {vertical-align: top !important;} .va-mi_pc {vertical-align: middle !important;} .va-bo_pc {vertical-align: bottom !important;} }

/* テキストの太さ
------------------------------------------*/
.fw-bo {font-weight: bold;}
.fw-no {font-weight: normal;}

@media screen and(max-width: $breakPoint){ .fw-bo_sp {font-weight: bold !important;} .fw-no_sp {font-weight: normal !important;} }
@media screen and(min-width: $breakPoint){ .fw-bo_pc {font-weight: bold !important;} .fw-no_pc {font-weight: normal !important;} }

/* 一行ではみ出た部分を...
------------------------------------------*/
.to-el {white-space: nowrap; overflow: hidden; text-overflow: ellipsis;}

@media screen and(max-width: $breakPoint){ .to-el_sp {white-space: nowrap; overflow: hidden; text-overflow: ellipsis;} }
@media screen and(min-width: $breakPoint){ .to-el_pc {white-space: nowrap; overflow: hidden; text-overflow: ellipsis;} }

/* 折り返しなし・はみ出たら非表示
------------------------------------------*/
.to-el { white-space: nowrap; overflow: hidden; text-overflow: ellipsis; }

@media screen and(max-width: $breakPoint){ .to-el_sp {white-space: nowrap; overflow: hidden; text-overflow: ellipsis;} }
@media screen and(min-width: $breakPoint){ .to-el_pc {white-space: nowrap; overflow: hidden; text-overflow: ellipsis;} }


/*====================================================================================
余白の設定：top、right、bottom、left、topとbottom、leftとright
====================================================================================*/

/*------------------------------------------
paddingを与える
------------------------------------------*/
$i: 0;
@while $i <= 100 {
	.pt#{$i}  {padding-top   : #{$i}px !important;}
	.pr#{$i}  {padding-right : #{$i}px !important;}
	.pb#{$i}  {padding-bottom: #{$i}px !important;}
	.pl#{$i}  {padding-left  : #{$i}px !important;}
	.ptb#{$i} {padding-top   : #{$i}px !important; padding-bottom: #{$i}px !important;}
	.plr#{$i} {padding-left  : #{$i}px !important; padding-right : #{$i}px !important;}
	$i: $i + 10;
}

.ptAuto  {padding-top   : auto !important;}
.prAuto  {padding-right : auto !important;}
.pbAuto	 {padding-bottom: auto !important;}
.plAuto  {padding-left  : auto !important;}
.ptbAuto {padding-top   : auto !important; padding-bottom: auto !important;}
.plrAuto {padding-left  : auto !important; padding-right : auto !important;}

@media screen and(max-width: $breakPoint){ $i: 0; @while $i <= 100 { .pt#{$i}_sp {padding-top: #{$i}px;} .pr#{$i}_sp {padding-right: #{$i}px;} .pb#{$i}_sp {padding-bottom: #{$i}px;} .pl#{$i}_sp {padding-left: #{$i}px;} .ptb#{$i}_sp {padding-top: #{$i}px;  padding-bottom: #{$i}px;} .plr#{$i}_sp {padding-left: #{$i}px; padding-right: #{$i}px;} $i: $i + 10;} .ptAuto_sp {padding-top: auto;} .prAuto_sp {padding-right: auto;} .pbAuto_sp {padding-bottom: auto;} .plAuto_sp {padding-left: auto;} .ptbAuto_sp {padding-top: auto; padding-bottom: auto;} .plrAuto_sp {padding-left: auto; padding-right: auto;} }
@media screen and(min-width: $breakPoint){ $i: 0; @while $i <= 100 { .pt#{$i}_pc {padding-top: #{$i}px;} .pr#{$i}_pc {padding-right: #{$i}px;} .pb#{$i}_pc {padding-bottom: #{$i}px;} .pl#{$i}_pc {padding-left: #{$i}px;} .ptb#{$i}_pc {padding-top: #{$i}px;  padding-bottom: #{$i}px;} .plr#{$i}_pc {padding-left: #{$i}px; padding-right: #{$i}px;} $i: $i + 10;} .ptAuto_pc {padding-top: auto;} .prAuto_pc {padding-right: auto;} .pbAuto_pc {padding-bottom: auto;} .plAuto_pc {padding-left: auto;} .ptbAuto_pc {padding-top: auto; padding-bottom: auto;} .plrAuto_pc {padding-left: auto; padding-right: auto;} }

/*------------------------------------------
marginを与える
------------------------------------------*/
$i: 0;
@while $i <= 100 {
	.mt#{$i}  {margin-top   : #{$i}px !important;}
	.mr#{$i}  {margin-right : #{$i}px !important;}
	.mb#{$i}  {margin-bottom: #{$i}px !important;}
	.ml#{$i}  {margin-left  : #{$i}px !important;}
	.mtb#{$i} {margin-top   : #{$i}px !important; margin-bottom: #{$i}px !important;}
	.mlr#{$i} {margin-left  : #{$i}px !important; margin-right : #{$i}px !important;}
	$i: $i + 10;
}

.mtAuto  {margin-top   : auto !important;}
.mrAuto  {margin-right : auto !important;}
.mbAuto	 {margin-bottom: auto !important;}
.mlAuto  {margin-left  : auto !important;}
.mtbAuto {margin-top   : auto !important; margin-bottom: auto !important;}
.mlrAuto {margin-left  : auto !important; margin-right: auto !important;}

@media screen and(max-width: $breakPoint){ $i: 0; @while $i <= 100 { .mt#{$i}_sp {margin-top: #{$i}px;} .mr#{$i}_sp {margin-right: #{$i}px;} .mb#{$i}_sp {margin-bottom: #{$i}px;} .ml#{$i}_sp {margin-left: #{$i}px;} .mtb#{$i}_sp {margin-top: #{$i}px;  margin-bottom: #{$i}px;} .mlr#{$i}_sp {margin-left: #{$i}px; margin-right: #{$i}px;} $i: $i + 10 } .mtAuto_sp {margin-top: auto;} .mrAuto_sp {margin-right: auto;} .mbAuto_sp	{margin-bottom: auto;} .mlAuto_sp {margin-left: auto;} .mtbAuto_sp {margin-top: auto; margin-bottom: auto;} .mlrAuto_sp {margin-left: auto; margin-right: auto;} }
@media screen and(min-width: $breakPoint){ $i: 0; @while $i <= 100 { .mt#{$i}_pc {margin-top: #{$i}px;} .mr#{$i}_pc {margin-right: #{$i}px;} .mb#{$i}_pc {margin-bottom: #{$i}px;} .ml#{$i}_pc {margin-left: #{$i}px;} .mtb#{$i}_pc {margin-top: #{$i}px;  margin-bottom: #{$i}px;} .mlr#{$i}_pc {margin-left: #{$i}px; margin-right: #{$i}px;} $i: $i + 10;} .mtAuto_pc {margin-top: auto;} .mrAuto_pc {margin-right: auto;} .mbAuto_pc	{margin-bottom: auto;} .mlAuto_pc {margin-left: auto;} .mtbAuto_pc {margin-top: auto; margin-bottom: auto;} .mlrAuto_pc {margin-left: auto; margin-right: auto;} }


/*====================================================================================
限定
====================================================================================*/
@media screen and(max-width: $breakPoint){ .pc {display: none !important;} }
@media screen and(min-width: $breakPoint){ .sp {display: none !important;} }

/*====================================================================================
横並びの設定
====================================================================================*/
.colBox{
	display:table;
	table-layout: fixed;
	width: 100%;
	.col-l,.col-r{display: table-cell;}
}

/*------------------------------------------
解除
------------------------------------------*/
.cf:after{content: ""; display: block; width: 100%; height: 0; clear: both;}

@media screen and(max-width: $breakPoint){.cf_sp:after{content: ""; display: block; width: 100%; height: 0; clear: both;}}
@media screen and(min-width: $breakPoint){.cf_pc:after{content: ""; display: block; width: 100%; height: 0; clear: both;}}

